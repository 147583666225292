import PropTypes from 'prop-types';
import styled from 'styled-components';
import LibAccordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import { theme } from '_constants/theme';

const StyledContainer = styled.div`
  padding: ${theme.spaces.medium}px;
  margin-bottom: ${theme.spaces.medium}px;
  border-radius: 10px;
  border: 1px solid #e0d4fe;
  box-shadow: 0px 1px 2px 0px #1018280d, 0px 30px 40px 0px #6941c61a;
  background: ${theme.colors.white};
`;

const StyledHeader = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: ${theme.spaces.regular}px;
`;

const StyledTitle = styled.div`
  flex-grow: 1;
`;

const StyledBody = styled.div`
  padding-right: ${theme.spaces.large4X}px;
`;

const AccordionItem = ({
  eventKey,
  title,
  children,
  open,
  openIcon,
  closeIcon,
  onToggle,
}) => {
  const handleToggle = useAccordionButton(
    eventKey,
    () => onToggle && onToggle(eventKey)
  );

  return (
    <StyledContainer>
      <StyledHeader onClick={handleToggle}>
        <StyledTitle>{title}</StyledTitle>
        <StyledIconContainer>{open ? openIcon : closeIcon}</StyledIconContainer>
      </StyledHeader>
      <LibAccordion.Collapse eventKey={eventKey}>
        <StyledBody>{children}</StyledBody>
      </LibAccordion.Collapse>
    </StyledContainer>
  );
};

AccordionItem.propTypes = {
  eventKey: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  open: PropTypes.bool,
  openIcon: PropTypes.node,
  closeIcon: PropTypes.node,
  onToggle: PropTypes.func,
};

export default AccordionItem;
