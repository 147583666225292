import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LibAccordion from 'react-bootstrap/Accordion';

import { usePrevious } from '_hooks';

import AccordionItem from './AccordionItem';

const Accordion = ({ openIcon, closeIcon, items = [], onChange }) => {
  const [ready, setReady] = useState(false);
  const [openKeys, setOpenKeys] = useState(undefined);
  const prevItems = usePrevious(items);

  const handleToggle = (eventKey) => {
    setOpenKeys((prevOpenKeys) => {
      if (prevOpenKeys.includes(eventKey)) {
        return prevOpenKeys.filter((openKey) => openKey !== eventKey);
      }

      return [...prevOpenKeys, eventKey];
    });
  };

  useEffect(() => {
    if (!prevItems?.length && items.length) {
      setOpenKeys(() => {
        const defaultKeys = [];
        items.forEach(({ openByDefault }, idx) => {
          if (openByDefault) {
            defaultKeys.push(`${idx}`);
          }
        });
        return defaultKeys;
      });
    }
  }, [prevItems, items]);

  useEffect(() => {
    onChange && onChange(openKeys);
  }, [openKeys, onChange]);

  useEffect(() => {
    if (openKeys) {
      setReady(true);
    }
  }, [openKeys]);

  if (!ready) {
    return null;
  }

  return (
    <LibAccordion defaultActiveKey={[...openKeys]} alwaysOpen>
      {items.map(({ title, body }, idx) => (
        <AccordionItem
          key={idx}
          eventKey={`${idx}`}
          title={title}
          open={openKeys.includes(`${idx}`)}
          openIcon={openIcon}
          closeIcon={closeIcon}
          onToggle={handleToggle}
        >
          {body}
        </AccordionItem>
      ))}
    </LibAccordion>
  );
};

Accordion.propTypes = {
  openIcon: PropTypes.node,
  closeIcon: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      openByDefault: PropTypes.bool,
      title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
      ]),
      body: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
      ]),
    })
  ),
  onChange: PropTypes.func,
};

export default Accordion;
