import styled from 'styled-components';

import { SECTION_ID } from '_constants/ui';
import { ICONS_ALIASES } from '_constants/icons';
import { theme } from '_constants/theme';

import Accordion from '_components/atomic/Accordion';
import Icon from '_components/atomic/Icon';

const StyledOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${theme.colors.white};
`;

const StyledInnerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${theme.spaces.large}px;

  position: relative;
  overflow: hidden;

  max-width: ${theme.spaces.large30X * 10}px;
  width: 100%;

  padding-top: ${theme.spaces.large6X}px;
  padding-bottom: ${theme.spaces.large15X}px;
  padding-left: ${theme.spaces.large28X - 1}px;
  padding-right: ${theme.spaces.large28X - 1}px;

  @media ${theme.devices.tablet} {
    flex-direction: column;
    gap: ${theme.spaces.large4X + 2}px;
    padding-left: ${theme.spaces.regular}px;
    padding-right: ${theme.spaces.regular}px;
    padding-top: ${theme.spaces.regular}px;
    padding-bottom: ${theme.spaces.large8X}px;
  }
`;

const StyledContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &:last-child {
    width: 85%;
  }

  @media ${theme.devices.tablet} {
    align-items: center;

    &:last-child {
      width: 100%;
    }
  }
`;

const StyledLabel = styled.div`
  padding-left: ${theme.spaces.regular - 2}px;
  padding-right: ${theme.spaces.regular - 2}px;
  padding-top: ${theme.spaces.smallest}px;
  padding-bottom: ${theme.spaces.smallest}px;

  border-radius: 66px;
  background: #f9f5ff;

  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.indigo.primary};
`;

const StyledTitle = styled.div`
  padding-top: ${theme.spaces.medium}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large8X}px;
  font-weight: 600;
  line-height: 39.6px;
  letter-spacing: -0.03em;
  background: linear-gradient(270.46deg, #101828 47.44%, #6941c6 102.37%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${theme.devices.tablet} {
    font-size: ${theme.fontSizes.large4X}px;
    line-height: 30.8px;
    text-align: center;
  }
`;

const StyledFAQTitle = styled.div`
  font-family: 'Inter';
  font-size: ${theme.fontSizes.large}px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.primary};
`;

const StyledFAQBody = styled.div`
  padding-top: ${theme.spaces.regular}px;
  font-family: 'Inter';
  font-size: ${theme.fontSizes.regular}px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.gray.secondary};
`;

const GeneralFAQSection = () => {
  return (
    <StyledOuterContainer id={SECTION_ID.faq}>
      <StyledInnerContainer>
        <StyledContentBox>
          <StyledLabel>FAQ</StyledLabel>
          <StyledTitle>Frequently asked question</StyledTitle>
        </StyledContentBox>
        <StyledContentBox>
          <Accordion
            openIcon={
              <Icon
                alias={ICONS_ALIASES.minusCircle}
                color={theme.colors.indigo.primary}
              />
            }
            closeIcon={
              <Icon
                alias={ICONS_ALIASES.plusCircle}
                color={theme.colors.indigo.primary}
                opacity="0.5"
              />
            }
            items={[
              {
                openByDefault: true,
                title: (
                  <StyledFAQTitle>
                    What is bookkeeping, and why do I need it for my business?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Bookkeeping is the systematic recording, organizing, and
                    tracking of financial transactions in a business. It&apos;s
                    essential for your business because it ensures accurate
                    financial records, helps with budgeting, tax preparation,
                    and provides insights into your financial health, enabling
                    better decision-making.
                  </StyledFAQBody>
                ),
              },
              {
                openByDefault: false,
                title: (
                  <StyledFAQTitle>
                    Can I handle bookkeeping for my small business on my own?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Yes, it&apos;s feasible for small business owners to manage
                    their own bookkeeping. However, it requires a good
                    understanding of accounting principles and a commitment to
                    regularly updating financial records. As your business
                    grows, the complexity of bookkeeping can increase, so
                    it&apos;s important to assess whether you have the necessary
                    time and skills to maintain accuracy and compliance.
                  </StyledFAQBody>
                ),
              },
              {
                openByDefault: false,
                title: (
                  <StyledFAQTitle>
                    What benefits does professional bookkeeping offer my
                    business?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Monexa precisely tracks your financial transactions, ensures
                    accurate financial reporting, and aids in compliance with
                    tax laws, which can be intricate and constantly evolving.
                    This meticulous attention to detail in financial management
                    not only safeguards your business from costly errors but
                    also provides clear, actionable data to support strategic
                    business decisions.
                  </StyledFAQBody>
                ),
              },
              {
                openByDefault: false,
                title: (
                  <StyledFAQTitle>
                    When should I start thinking about tax preparation for my
                    business?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Tax preparation should be a consideration as soon as your
                    business starts generating revenue, regardless of the
                    amount. This early attention to tax matters ensures you
                    maintain accurate financial records and stay informed about
                    potential deductions, which is essential for businesses of
                    any size. Early planning also helps to identify and address
                    any financial issues well before tax deadlines, simplifying
                    the process and minimizing stress.
                  </StyledFAQBody>
                ),
              },
              {
                openByDefault: false,
                title: (
                  <StyledFAQTitle>
                    How can professional tax preparation services benefit me?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Professional tax services can help you save money by finding
                    all the tax breaks you&apos;re eligible for. They also make
                    sure your taxes are done right, so you avoid costly
                    mistakes, fees, and penalties. This means you can have peace
                    of mind knowing your taxes are taken care of correctly.
                  </StyledFAQBody>
                ),
              },
              {
                openByDefault: false,
                title: (
                  <StyledFAQTitle>
                    Can you help me with tax planning throughout the year, not
                    just during tax season?
                  </StyledFAQTitle>
                ),
                body: (
                  <StyledFAQBody>
                    Absolutely, we offer comprehensive tax planning services all
                    year round, not just during the traditional tax season. Our
                    team is dedicated to assisting you every quarter, ensuring
                    ongoing support and strategic guidance to optimize your tax
                    position and keep your finances on track throughout the
                    entire year.
                  </StyledFAQBody>
                ),
              },
            ]}
          />
        </StyledContentBox>
      </StyledInnerContainer>
    </StyledOuterContainer>
  );
};

export default GeneralFAQSection;
